import React from 'react';
import {graphql} from 'gatsby';
import ServicePragueTemplate from '../components/cleaningServices/template';

const ServicePrague14Page = ({data}) => <ServicePragueTemplate data={data} />;

export default ServicePrague14Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "service_prague_14", "seo_prague_14", "homepage"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    backgroundService: file(relativePath: {eq: "hourly-wife-service.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    background: file(relativePath: {eq: "service-prague.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "service-prague-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "service-prague-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
